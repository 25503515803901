<template>
  <v-container fluid class="pa-0">
    <v-col
      cols="12"
      class="cover-layer px-10 py-4"
      :class="backgroundClass"
      v-if="!isWebOrdersModeEnabled || !isVerifiedPayment"
    >
      <!-- Si el modo no es webOrders sigue todo como esta-->
      <v-row
        class="fill-height align-center justify-center"
        :style="isMinimized ? '' : 'height: 100vh;'"
      >
        <v-col class="text-center" cols="12">
          <v-row class="align-center">
            <v-col :cols="isMinimized ? '3' : '12'">
              <v-icon v-if="!isVerifiedPayment" :size="150" color="white">{{
                "mdi-lan-pending"
              }}</v-icon>
              <v-icon v-else :size="isMinimized ? '60' : '150'" color="white">{{
                successPayment
                  ? "mdi-checkbox-marked-circle-outline"
                  : "mdi-close-circle-outline"
              }}</v-icon>
            </v-col>
            <v-col v-if="!isVerifiedPayment" cols="12" class="py-15">
              <p
                class="text-h5 font-weight-regular text-center white--text mb-0"
              >
                {{ "Verificando Pago..." }} <br />
                {{ "Aguarde por favor" }}
              </p>
            </v-col>
            <v-col v-else-if="isMinimized" cols="9" class="py-15">
              <p
                class="text-h5 font-weight-regular text-start white--text mb-0"
              >
                {{
                  successPayment
                    ? "La operación se ha realizado con éxito."
                    : "No se ha podido completar la operación."
                }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!-- MODO KIOSCO-->
    <v-container
      v-if="!isPartial && isKioskModeEnabled"
      class="px-4 fill-height py-10"
      id="menu-order-details-payment-thank-you"
    >
      <v-row
        v-if="$store.state.menu.profile.shopLogoUrl"
        justify="center"
        class="inner-container"
      >
        <base-img
          :src="$store.state.menu.profile.shopLogoUrl"
          contain
          max-width="8rem"
          max-height="5rem"
        />
      </v-row>
      <v-col cols="12" class="text-center">
        <p
          v-if="!successPayment"
          class="text-center text-h5 font-weight-regular pt-5"
        >
          {{ "Disculpe las molestias" }}
        </p>
        <p v-else class="text-center text-h5 font-weight-regular pt-5">
          {{ placeCustomerBought }}
        </p>
        <v-img
          v-if="getQrUrl !== null"
          justify="center"
          height="50%"
          :src="getQrUrl"
        />
        <p
          v-if="payerEmail !== null && successPayment"
          class="text-center font-weight-regular"
        >
          Te mandamos un comprobante de pago a
          <strong>{{ payerEmail }}</strong
          >. De todos modos te recomendamos que hagas un screenshot de esta
          pantalla para que puedas presentar en la tienda!
        </p>

        <v-icon v-else size="48" class="chef-icon">
          $vuetify.icons.chefHat
        </v-icon>
      </v-col>
    </v-container>
    <!-- MODO WEBORDERS-->
    <v-container
      v-if="!isPartial && isWebOrdersModeEnabled && isVerifiedPayment"
      id="menu-order-details-payment-thank-you"
    >
      <!-- Si el pago es exitoso-->
      <div v-if="successPayment" class="weborder-message">
        <v-icon class="weborder-confirm-icon" size="200px" color="#4CAF50">
          mdi-checkbox-marked-circle
        </v-icon>
        <div class="weborder-message-text">
          <p class="weborder-message-title" style="color: #4caf50">
            ¡Gracias por tu pedido!
          </p>
          <p v-if="payerEmail !== null && successPayment">
            Estaremos informándote sobre su estado al correo
            <br />
            <strong>{{ payerEmail }}</strong>
            <br />
            ¡Esperamos que lo disfrutes!
          </p>
        </div>
      </div>
      <!-- Si hubo un problema-->
      <div v-else class="weborder-message">
        <v-icon class="weborder-confirm-icon" size="200px" color="#ffbd3b">
          mdi-alert-circle
        </v-icon>
        <div class="weborder-message-text">
          <p class="weborder-message-title" style="color: #ffbd3b">
            Validando pago
          </p>
          <p>
            Todavía no pudimos validar el pago. Por favor actualiza la página
            para confirmar el estado del pago.
          </p>
        </div>
      </div>
    </v-container>
    <!-- MODO VALIDATOR-->
    <v-container
      v-if="!isPartial && isValidatorModeEnabled"
      class="px-4 fill-height py-10"
      id="menu-order-details-payment-thank-you"
    >
      <v-row
        v-if="$store.state.menu.profile.shopLogoUrl"
        justify="center"
        class="inner-container"
      >
        <base-img
          :src="$store.state.menu.profile.shopLogoUrl"
          contain
          max-width="8rem"
          max-height="5rem"
        />
      </v-row>
      <v-col cols="12" class="text-center">
        <p
          v-if="!successPayment"
          class="text-center text-h5 font-weight-regular pt-5"
        >
          {{ "Disculpe las molestias" }}
        </p>
        <p v-else class="text-center text-h5 font-weight-regular pt-5">
          {{ placeCustomerBought }}
        </p>
        <v-img
          v-if="getQrUrl !== null"
          justify="center"
          height="50%"
          :src="getQrUrl"
        />
        <p
          v-if="payerEmail !== null && successPayment"
          class="text-center font-weight-regular"
        >
          Te mandamos un comprobante de pago a
          <strong>{{ payerEmail }}</strong
          >. De todos modos te recomendamos que hagas un screenshot de esta
          pantalla para que puedas presentar en la tienda!
        </p>

        <v-icon v-else size="48" class="chef-icon">
          $vuetify.icons.chefHat
        </v-icon>
      </v-col>
    </v-container>
  </v-container>
</template>

<script>
import thankYouCommon from "@/mixins/firebase/thankYouCommon";
import qrCode from "@/mixins/qrCode";
import mobileCommon from "@/mixins/mobile";
import shopUtils from "@/mixins/shopUtils.js";

import {
  // submitDeliveryOrder,
  getIfIsValidPayment,
  // updateFailOrder,
} from "@/plugins/axios";

export default {
  name: "MenuOrderDetailsPaymentThankYou",
  mixins: [thankYouCommon, qrCode, mobileCommon, shopUtils],
  provide: {
    heading: { align: "start" },
  },

  data: () => ({
    isMinimized: false,
    paymentStatus: "Pending",
    payerEmail: null,
    qrUrl: null,
    shopName: null,
  }),

  computed: {
    placeCustomerBought() {
      if (this.shopName)
        return `¡Muchas gracias! Compraste en: ${this.shopName}`;
      else return null;
    },
    isDelivery() {
      return this.$route.meta.isDelivery;
    },
    isPartial() {
      return this.$route.meta.isPartial;
    },
    isVerifiedPayment() {
      return this.paymentStatus !== "Pending";
    },
    successPayment() {
      return this.paymentStatus === "Approved";
    },
    failurePayment() {
      return this.paymentStatus === "Failure";
    },
    backgroundClass() {
      let classString = "";

      if (!this.isMinimized) {
        classString += " overlay";
      }

      if (!this.isVerifiedPayment) {
        classString += " info";
      } else if (this.successPayment) {
        classString += " success";
      } else {
        classString += " error";
      }

      return classString;
    },
    getQrUrl() {
      return this.qrUrl;
    },
    getHelpContactCenter() {
      if (
        this.$store.state.menu.profile.helpContactCenter == null ||
        this.$store.state.menu.profile.helpContactCenter == ""
      )
        return null;

      return this.$store.state.menu.profile.helpContactCenter;
    },
  },

  async mounted() {
    const { table } = this.$route.query;
    this.verifyPaymentHandler(table);

    // await setTimeout(() => {
    //   this.isMinimized = true;
    // }, 2000);
    // if (!this.isDelivery) {
    //   if (this.isPartial && this.isTotallyPaid) {
    //     setTimeout(() => {
    //       const { commerceId, table } = this.$route.query;
    //       this.$router.push({
    //         name: "MenuOrder",
    //         query: { commerceId, table },
    //       });
    //     }, 7000);
    //   }
    //   if (this.successPayment && !this.isPartial) {
    //     setTimeout(() => {
    //       const { commerceId, table } = this.$route.query;
    //       this.$router.push({
    //         name: "MenuOrder",
    //         query: { commerceId, table },
    //       });
    //     }, 7000);
    //   }
    // }
  },

  methods: {
    async submitSuccessful({ uuid }) {
      // Check if is total
      if (!uuid) {
        if (
          this.$store.getters["order/status"] === "OPEN" ||
          this.$store.getters["order/status"] === "PARTIALLY_PAID"
        ) {
          this.$store.dispatch("order/updateSuccessfulTotalPayment", {});
        }
      } else {
        // Loop NOT_PAID and IN_PROGRESS
        this.$store.dispatch("order/updateSuccessfulPayment", {
          uuid,
        });

        // Update FireBase just uuid order
      }
    },

    async submitFailure({ uuid, table }) {
      if (table) {
        this.$store.dispatch("order/updateFailurePayment", {
          uuid,
          table,
        });
      }
    },

    verifyPayment(uuid, callBack) {
      if (this.isVerifiedPayment) {
        callBack();
        return;
      }

      const payload = {
        externalReference: this.$route.query.table
          ? this.$route.query.table
          : uuid,
        shopCode: this.$route.query.commerceId,
      };

      console.log(`getIfIsValidPayment - OUT`, payload);
      getIfIsValidPayment(payload)
        .then((res) => {
          console.log(`getIfIsValidPayment - IN`, res?.data);
          if (res?.data?.responseCode === 0) {
            this.paymentStatus = res?.data?.isOk ? "Approved" : "Failure";
          } else {
            console.error("El pago no fue procesado correctamente.", res?.data);
            this.paymentStatus = "Failure";
          }
          callBack(res);
        })
        .catch((error) => {
          console.error(error);
          this.paymentStatus = this.$route.meta.successPayment
            ? "Approved"
            : "Failure";
          callBack();
        });
    },

    // async fetchAndSubmitDeliveryOrder(uuid) {
    //   const payload = {
    //     TipAmount: this.$store.getters["deliveryCart/currentTip"].amount,
    //     TotalAmount: this.$store.getters["deliveryCart/totalPrice"],
    //     CustomerName: this.$store.state.deliveryCart.shippingInformation.name,
    //     CustomerAddress:
    //       this.$store.state.deliveryCart.shippingInformation.address,
    //     CustomerPhone: this.$store.state.deliveryCart.shippingInformation.phone,
    //     BOrderNumber: uuid ?? this.$store.state.deliveryCart.uuid,
    //     Details: this.$store.state.deliveryCart.products.map((element) => {
    //       return {
    //         Sku: element.sku,
    //         Name: element.name,
    //         UnitPrice: parseFloat(element.unitPrice),
    //         Quantity: parseFloat(element.quantity),
    //         Options: element.options?.map((optionsElement) => {
    //           return {
    //             Sku: optionsElement.sku,
    //             OptionName: optionsElement.name,
    //             UnitPrice: parseFloat(optionsElement.unitPrice),
    //             Quantity: parseFloat(optionsElement.quantity),
    //           };
    //         }),
    //       };
    //     }),
    //     ShopCode: this.$route.query.commerceId,
    //     PaymentMethod: "Online",
    //     PaymentId: this.$route.query.payment_id,
    //   };

    //   await submitDeliveryOrder(payload)
    //     .then(async (res) => {
    //       if (res?.data?.responseCode === 0) {
    //         if (res?.data?.qrFilePath) {
    //           this.generateQrCode(res?.data?.qrFilePath, (url) => {
    //             this.qrUrl = url;
    //             if (this.isMobile() == false) {
    //               this.downloadQr(this.getQrUrl, payload.BOrderNumber);
    //             }
    //           });
    //         }

    //         this.payerEmail = res?.data?.payerEmail;
    //         if (window.localStorage.getItem("deliveryUuid"))
    //           await this.cleanDeliveryOrder();
    //       } else {
    //         console.error("Failure", res?.data);
    //       }
    //     })
    //     .catch((error) => console.error(error));
    // },

    downloadQr(qrUrl, bOrderNumber) {
      var download = document.createElement("a");
      download.href = qrUrl;
      download.download = `Qr-${bOrderNumber}.png`;
      download.click();
    },

    async cleanDeliveryOrder() {
      const { commerceId, table } = this.$route.query;
      const cleanDeliveryPayload = {
        commerceId,
        deliveryUuid: table,
      };
      await this.$store.dispatch(
        "deliveryCart/cleanDeliveryOrder",
        cleanDeliveryPayload
      );
    },

    handleSubmit() {
      if (!this.isVerifiedPayment) return;

      if (this.successPayment) {
        this.submitSuccessful(this.$route.query);
      } else {
        this.submitFailure(this.$route.query);
      }
    },
    verifyPaymentHandler(uuid) {
      console.log("verifyPaymentHandler");
      this.verifyPayment(uuid, async (resp) => {
        if (resp?.data?.isOk) {
          if (resp?.data?.qrFilePath) {
            this.generateQrCode(resp?.data?.qrFilePath, (url) => {
              this.qrUrl = url;
              if (this.isMobile() == false) {
                this.downloadQr(this.getQrUrl, uuid);
              }
            });
          }

          this.payerEmail = resp?.data?.payerEmail;
          this.shopName = resp?.data?.shopName;
          if (window.localStorage.getItem("deliveryUuid"))
            await this.cleanDeliveryOrder();
        }
        setTimeout(() => {
          this.isMinimized = true;
        }, 2000);

        if (this.isDelivery) {
          // if (this.successPayment) {
          //   // this.$store.dispatch("deliveryCart/initialize");
          //   this.fetchAndSubmitDeliveryOrder(uuid);
          // } else if (this.failurePayment) {
          //   this.updateFailOrder();
          // }
        } else {
          if (this.isPartial && this.isTotallyPaid) {
            setTimeout(() => {
              const { commerceId, table } = this.$route.query;
              this.$router.push({
                name: "MenuOrder",
                query: { commerceId, table },
              });
            }, 7000);
          }
          if (
            this.successPayment &&
            !this.isPartial &&
            !this.isWebOrdersModeEnabled
          ) {
            setTimeout(() => {
              const { commerceId, table } = this.$route.query;
              this.$router.push({
                name: "MenuOrder",
                query: { commerceId, table },
              });
            }, 7000);
          }
        }

        this.handleSubmit();
      });
    },
    // async updateFailOrder() {
    //   const payload = {
    //     code: this.$store.getters["deliveryCart/uuid"],
    //   };

    //   try {
    //     await updateFailOrder(payload);
    //   } catch (ex) {}
    // },
  },

  watch: {
    isTotallyPaid() {
      if (this.isPartial && this.isTotallyPaid) {
        setTimeout(() => {
          const { commerceId, table } = this.$route.query;
          this.$router.push({
            name: "MenuOrder",
            query: { commerceId, table },
          });
        }, 7000);
      }
    },
    "$store.state.order.products": {
      deep: true,
      handler() {
        this.verifyPaymentHandler(this.$store.getters["order/uuid"]);
      },
    },
  },
};
</script>

<style scoped>
.weborder-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.weborder-message-icon {
  display: flex;
  justify-content: center;
}
.weborder-message-text {
  display: flex;
  flex-direction: column;
  width: 350px;
  text-align: center;
}
.weborder-message-title {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 25px;
}

.cover-layer {
  width: 100vw;

  min-height: auto;
  transition: min-height 1s ease-out;
  overflow: hidden;
}
.overlay {
  min-height: 100vh;
  transition: min-height 1s ease-in;

  left: 0;
  z-index: 9;
  position: absolute;
}

.inner-container {
}
.chef-icon {
  padding-top: 1.5rem;
}
</style>
